/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import Layout from "../components/Layout"
import useSiteMetaData from "../static_queries/useSiteMetadata"

export default function Info() {
  const { infoData } = useSiteMetaData()
  return (
    <Layout page="info" bgColor={infoData.background_color}>
      <section
        sx={{
          maxWidth: "800px",
          padding: ["1.5rem 1.25rem", "2rem", "3rem"],
          p: { color: "lighttext", fontSize: 4, marginBottom: "1rem" },
          h2: { marginBottom: "1rem" },
          div: { marginBottom: "1rem" },
        }}
      >
        <Styled.h2>
          <div dangerouslySetInnerHTML={{ __html: infoData.description }}></div>
          <div dangerouslySetInnerHTML={{ __html: infoData.cta }}></div>
        </Styled.h2>
        <ul>
          <li>
            <Styled.p>
              <a href={`mailto:${infoData.contact.email}`}>
                Email: {infoData.contact.email}
              </a>
            </Styled.p>
          </li>
          <li>
            <Styled.p>
              <a
                href={`https://twitter.com/${infoData.contact.twitter_handle}`}
              >
                Twitter: @{infoData.contact.twitter_handle}
              </a>
            </Styled.p>
          </li>
          <li>
            <Styled.p>
              <a href={`https://github.com/${infoData.contact.github_handle}`}>
                Github: {infoData.contact.github_handle}
              </a>
            </Styled.p>
          </li>
        </ul>

        <br />
        <Styled.h3>Privacy Options</Styled.h3>
        <a href="#" onClick={() => window.cookiehub.openSettings()}>
          Open cookie settings
        </a>
      </section>
    </Layout>
  )
}
